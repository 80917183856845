.correction3{
    background-color: rgb(231, 173, 173);
    border-left: 2rem solid rgb(237, 10, 10);
}
.correction3 h4{
    font-size: 1.3rem;
    font-weight: bold;
}
.correction3 p{
    font-size: 1rem;
    font-weight: bold;
}

button.accordion-button{
    background-color: black !important;
    color: green !important;
}
button.accordion-button.collapsed{
    color: blue !important;
}
button.accordion-button::after{
    content: "";
}