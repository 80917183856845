.mon-compte .box{
    background: url(../../image/img8.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.mon-compte .box:hover{
    border: none !important;
}
.mon-compte a{
    color: white;
}
.mon-compte a:hover, .mon-compte a.active{
    background-color: rgb(67, 67, 67);
}
.mon-compte .menu{
    width: 3rem;
    height: 3rem;
}
.mon-compte .button{
    width: 50rem;
    margin-bottom: 10rem;
}
.mon-compte .button:hover{
    background-color: rgb(34, 34, 255);
}
.mon-compte .icon{
    right: 0;
    top: 50%;
    transform: translate(-100%, -50%);
}