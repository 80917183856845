.section1{
    height: 65vh;
    min-height: 400px;
}
.section1 img{
    z-index: 5;
    object-fit: cover;
}
.section1 .gradient3{
    z-index: 10;
    background: rgba(0, 0, 0, 0.539);
}