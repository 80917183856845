.question{
    background: url(../../image/logo.png);
    background-size: cover;
    background-position: center;
}
.answers{
    list-style: none;
}
.answer{
    background: rgba(215, 215, 215, 0.728);
    font-weight: normal;
}
ul.answers li{
    width: 100%;
    max-width: 650px;
}
.questionLoader{
    background-color: rgba(0, 0, 0, 0.385);
    z-index: 999;
}