.login{
    height: 75vh;
    min-height: 525px;
    background-image: url(../../image/img2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.login .container{
    
    width: 100%;
    max-width: 500px;
    margin: 0 2rem;
    border: 2px solid blue;
    background-color: rgba(255, 255, 255, 0.339);
    border-radius: 20px;
}
/* .login .logo{
    width: 45%;
    height: 100px;
    align-self: center;
} */
.gradient4{
    background-color: rgba(255, 255, 255, 0.339);
}
input[type="text"], input[type="password"]{
    border: 1px solid blue;
}
.login a{
    text-decoration: none;
}