.box7{
    max-width: 900px;
    width: 100%;
    max-height: 600px;
    height: 100%
}
.gradient5{
    background-color: rgba(0, 0, 0, 0.696);
}
.close{
    height: 3rem;
    width: 3rem;
    transform: translate(50%, -50%);
}
.close:hover{
    color: red;
}
.box7 .img{
    width: 40%;
    height: 100%;
}
.box7 .img img{
    object-fit: cover;
}
.box7 .auth{
    width: 60%;
    background: white;
}
.box7 .item{
    background: rgb(162, 160, 160)
}
.box7 .activeitem{
    background: rgb(76, 82, 250)
}
@media (max-width: 765px) {
    .box7 .auth{
        width: 100%;
    }
}
