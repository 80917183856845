.container{
    width: 100%;
    max-width: 1200px;
}
.container p{
    text-align: left;
}
.attention{
    color: rgb(233, 94, 94);
    background-color: pink;
    border-left: 10px solid rgb(243, 154, 169);
}
.bg-button1{
    background: linear-gradient(to right, green, rgb(77, 204, 77));
    border: 1px solid green;
}
.bg-button2{
    background: linear-gradient(to right, rgb(70, 70, 245), rgb(94, 94, 174));
    border: 1px solid blue;
}
.border-blue{
    background: none;
    border-bottom: 2px solid blue;
}
.gradient-sujet{
    background-color: rgba(255, 255, 255, 0.361);
}