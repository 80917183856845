
.navblue{
    background: rgb(34, 34, 255);
}
.nav1{
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    
    
    height: 3rem;
    font-size: 1rem;
}
.nav1 a:hover, .nav1 a.active{
    opacity: 0.5;
}
.nav1 .navItems1{
    list-style: none;
    height: 100%;
}
.nav1 .navItems1>li{
    height: 100%;
    border-left: 1px solid white;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0 1.5rem;
}
.nav1 a{
    color: white;
    text-decoration: none;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.nav2{
    
    padding: 1.5rem;
    justify-content: center;
    position: relative;
}
.nav2 a{
    color: black;
    text-decoration: none;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.nav2 a:hover, .nav2 div.drop:hover, .nav2 a.active{
    color:rgb(34, 34, 255);
}
.droplist{
    top: 100%;
    padding: 1rem;
    width: 200%;
    color:rgb(34, 34, 255);
    z-index: 1000;
    background: white;
    position: absolute;
}
.droplist a{
    color:rgb(34, 34, 255);
}
.navicon:hover{
    opacity: 0.5;
}
.nav3{
    background-color: rgba(0, 0, 0, 0.348);
    z-index: 100;
}
.nav3 .navitems{
    width: 28rem;
    overflow-y: scroll;
}
.nav3 .navitems a,.nav3 .drop{
    padding: 1.5rem 0 1.5rem 1.5rem;
    color: black;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.507);
    font-size: 1.1rem;
    font-weight: 400;
}
.nav3 .navitems a.active{
    color: rgb(34, 34, 255);
}
.nav3 .navitems div.drop div{
    font-size: 1.1rem;
    font-weight: 400;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.501);
    padding: 1.5rem 1.5rem;
}
.subitem{
    opacity: 0.5;
}
.bgblue{
    background-color: rgb(34, 34, 255);
    color: white;
}
.logo{
    margin-right: 15rem;
}
@media (max-width: 1200px) {
    .nav2{
        justify-content: space-between;
    }
    .logo{
        margin-right: 0;
    }
    .nav1{
        justify-content: center;
    }
}