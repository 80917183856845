.section5{
    background-image: url(../../image/img10.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    z-index: 2;
}
.gradient2{
    background-color: rgba(0, 0, 0, 0.242);
}