.thead{
    border-bottom: 2px solid blue;
    background-color: gray;
}
.tr-head{
   background-color: gray; 
   font-size: 1.2rem;
}
.subsription td{
    padding: 2rem 1rem;
}