.test-section{
    padding: 7rem 10rem;
}
.test-resultat{
    padding: 7rem 10rem;
}
.test-resultat p{
    text-align: left;
}
.resultat-center{
    width: 80%;
    max-width: 800px;
}
.text-width{
    width: 150px;
}
.resultat{
    background-color: rgb(219, 211, 211);
}
.test-oral-container{
    width: 100%;
    max-width: 1000px;
}
.point{
    height: 5rem;
    border-top: 2px solid rgb(146, 139, 139);
    border-bottom: 2px solid rgb(146, 139, 139);
}
.test-gradient{
    background-color: #d7d7d7ba;
}
@media (max-width: 750px) {
    .test-section, .test-resultat {
        padding: 2rem 4rem;
    }
    html{
        font-size: 0.6rem;
    }
}