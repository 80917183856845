*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box !important;
}
html{
  font-size: 12px;
  width: 100%;
  overflow-x: hidden;
}
