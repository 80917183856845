.blogitem2{
    max-width: 1200px;
    width: 100%;
}
.immigration-text{
    max-width: 850px;
    width: 100%;
}
.immigration-text p{
    text-align: justify;
    font-size: 1.2rem;
}
.immigration-text h1{
    font-size: 2.5rem;
    text-align: justify;
}
.immigration-text h2{
    font-size: 2rem;
    text-align: justify;
}
.immigration-text .box{
    width: 300px;
    height: 170px;
}
.blogbox1{
    min-height: 800px;
}
.immigration-box2 {
    max-width: 1200px;
    width: 100%;
}
.immigration-box2 .dot{
    width: 0.7rem;
    height: 0.7rem;
}
.submit2{
    width: 20rem;
    text-align: center;
}
.immigration-box3{
    margin-top: 3rem;
}
.immigration-box3 *{
    text-align: justify;
}
.immigration-box2 .circle3{
    width: 3rem;
    height: 3rem;
}
.immigration .box:hover{
    border: 1px solid blue !important;
}
.box:hover .dot{
    background-color: blue !important;
}
.blogbox2{
    margin-top: -30px;
    z-index: 10;
    width: 95%;
}
.img{
    object-fit: cover;
    
}
.gradient5{
    display: none;
}
.blogitem2:hover .gradient5{
    background-color: rgba(5, 5, 5, 0.314);
    display: flex;
    z-index: 4;
    
}
.blogitem2:hover .blogbox2{
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
}
.blogbrand{
    transform: translate(-50%, -50%);
}
a{
    text-decoration: none;
}
.blogitem2 .date{
    top: 1rem;
    left: 1rem;
    width: 5rem;
    height: 5rem;
    z-index: 20;
}
.blogitem2 ul{
    list-style: none;
    color: gray ;
}
.textgray{
    color: gray;
}
@media (max-width: 635px) {
    .blogbox2{
        margin-left: 0;
    }
}
