.layout2 p{
    text-align: left;
    font-size: 1.5rem;
}
.layout2 li{
    font-size: 1.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.authentification{
    z-index: 10;
}