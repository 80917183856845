.font-price{
    font-size: 4rem;
    font-weight: 800;
}
.box2{
    width: 150px;
    height: 150px;
    transform: rotate(45deg);
}
.title{
    
    height: 150px;
}
.body{
    padding: 2rem;
    flex: 1;
}
.body ul{
    list-style: none;
}
.body ul li{
    border-bottom: 1px solid black;
}
.brand{
    padding: 0.3rem 0.5rem;
    width: 300px;
}
