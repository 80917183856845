#tri{
    font-weight: 500;
    width: 15rem;
    border-bottom: 2px solid black !important;
}
#tri:hover, #tri:active{
    border-bottom: 2px solid blue !important;
}
.boutique-container{
    border-top: 1px solid black;
    border-left: 1px solid black;
}
.boutique .bg{
    background-position: center;
    background-repeat: no-repeat;
}
.boutique-box{
    height: 30rem;
    border-right: 1px solid black;
    border-bottom: 1px solid black ;
}
.boutique-box:hover .shop{
    display: flex !important;
}
@media (max-width: 992px) {
    .boutique-box{
        width: calc(100%/3) !important;
        height: 25rem;
    }
}
@media (max-width: 730px) {
    .boutique-box{
        width: 50% !important;
        height: 20rem;
    }
}