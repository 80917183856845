.head{
    color: rgb(34, 34, 255);
}
.section2-container{
    width: 80%;
}
.section2-container img{
    object-fit: cover;
    border-radius: 4rem;
    border: 2px solid rgb(34, 34, 255);
    transition: 0.5s;
}
.section2-container img:hover{
    transform: translateY(-10px);
}
.section2-container h3{
    color: black;
    text-align: center;
}
.section2-container .button{
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
    background: linear-gradient(to left, rgb(2, 2, 79), rgb(34,34,255));
}