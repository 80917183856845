.box5{
    width: 100%;
    max-width: 1000px;
    text-align: justify;
}
.box5 .p1{
    max-width: 700px;
}
.box6>*{
    text-align: left;
}
.box6{
    width: 100%;
    max-width: 1150px;
}
.box6 h2, .box6 h3, .box6 h4{
    font-weight: bold;
}
.box6 p{
    font-size: 1.2rem;
}
.section{
    height: 500px;
}
.section img{
    z-index: 5;
    object-fit: cover;
}
.section .gradient3{
    z-index: 10;
    background: rgba(0, 0, 0, 0.539);
}