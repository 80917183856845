.test-section{
    padding: 7rem 10rem;
}
.test-container{
    width: 100%;
    max-width: 750px;
}
.point{
    height: 5rem;
    border-top: 2px solid rgb(146, 139, 139);
    border-bottom: 2px solid rgb(146, 139, 139);
}
.test-gradient{
    background-color: rgba(215, 215, 215, 0.728);
}
