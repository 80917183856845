.contact-section{
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -30rem;
    display: inline-block; 
    background-color: white ;
    z-index: 3;
}
.contact-section input, .contact-section textarea{
    padding: 0.5rem;
    font-size: 1.5rem;
    border-radius: 0.5rem;
    border: 2px solid rgb(34, 34, 255) !important;
}
.contact-section textarea{
    height: 15rem;
}
.contact-section .form{
    border: none !important;
}
button[type="submit"]{
    background: rgb(34, 34, 255);
    padding: 0.7em;
    border: none;
    border-radius: 0.5rem;
    color: white;
}
label{
    font-size: 1.2rem;
    font-weight: bold;
}
.red{
    color: red;
}

/* .contact .form{

}
.contact .info{

} */