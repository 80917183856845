.box1{
   column-count: 2;
   column-gap: 2rem;
}
.border_left{
   border-left: 2px solid blue;
   padding-left: 2rem;
}

@media (max-width: 760px) {
   .box1{
      column-count: 1;
   }
}