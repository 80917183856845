.panier .total{
    width: 400px; 
}
.panier table thead td{
    text-align: center;
}
/* .panier table tbody td{
    display: flex;
    align-items: center;
    justify-content: center;
} */
/* .panier .code{
    width: calc(100% - 400px)
} */
@media (max-width: 992px) {
    .panier .total{
        width: 100%;
    }
}