.missing .message{
    position: relative;
    width: 50%;
    max-width: 1000px;
    height: 300px;
}
.missing .message::before{
    content: "404";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(15rem, 20vw, 23rem) ;
    font-weight: 800;
    color: rgb(219, 212, 212);
    z-index: -1;
}