.bggray{
    background-color: #F8F8F8;
}
.box3{
    width: 100%;
    max-width: 1200px;
}
p.text-justify{
    text-align: justify;
    font-size: 1.75rem;
    margin: 1rem 0;
}