.item{
    background-color: rgb(34, 34, 255);
    margin: 0 2rem;
    
    width: 20%;
}
.font-icon{
    font-size: 7rem;
}
@media (max-width: 1200px) {
    .item{
        margin: 0 1rem;
    }
}
@media (max-width: 1000px) {
    .item{
        width: 40%
    }
}
@media (max-width: 763px) {
    .item{
        width: 80%
    }
}