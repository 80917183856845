.section1{
    height: 35rem;
    background-image: url(../../image/img2.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.gradient{
    background-color: rgba(0, 0, 255, 0.115);
    z-index: 500;
}
h1{
    font-size: 4rem;
    font-weight: 550;
    text-align: center;
}
p{
    font-size: 2rem;
    text-align: center;
}
.button{
    background-color: rgb(34, 34, 255);
    color: white;
    padding: 0.7rem 2.5rem;
    font-size: 1.5rem;
    text-decoration: none;
}
.button:hover{
    transform: scale(1.1);
    transition: 0.5s;
}

@media (max-width: 750px) {
    p{
        font-size: 1.5rem;
    }
    h1{
        font-size: 3rem;
    }
}

@media (max-width: 600px) {
    p{
        font-size: 1rem;
    }
    h1{
        font-size: 2rem;
    }
}