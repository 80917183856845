
footer a{
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
}
.foot-col{
    margin: 0 2rem;
}
.foot-col{
    width: 80%;
}
.navicons a{
    width: 3.5rem;
    height: 3.5rem;
    text-decoration: none;
    border: none;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
}
footer .authcontainer{
    background: rgb(101, 101, 236);
}
footer .authcontainer div:hover{
    background-color: white;
    color: black;
}
@media (max-width: 992px) {
    footer a{
        border-bottom: none;
    }
}