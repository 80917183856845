.methodologie1{
    width: 100%;
    max-width: 850px;
}
.box1 p{
    font-size: 1.3rem;
    font-family: 'Times New Roman', Times, serif;
}
.methodologie p, .methodologie li{
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}