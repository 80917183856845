.whatsapp-icon{
    width: 5rem;
    height: 5rem;
    bottom: 2rem;
    right: 2rem;
    z-index: 200;
    background: green;
}
.zapcircle{
    width: 2.7rem;
    height: 2.7rem;
    align-items: center;
    justify-content: center;
    transform: scale(1.3);
}
.bggreen{
    background: green;
}
.whatsapp-box{
    bottom: 8rem;
    right: 2rem;
    z-index: 200;
    width: 100%;
    max-width: 350px;
    border-radius: 1rem;
    overflow: hidden;
    margin-left: 2rem;
}
.whatsapp-box h3{
    font-weight: bold;
}
.whatsapp-box .box{
    background: rgb(218, 236, 214);
    border-left: 2px solid green;
    border-radius: 0 5px 5px 5px;
    overflow: hidden;
}
.fs_icon{
    font-size: 3rem;
}