.correction1{
    width: 100%;
    max-width: 1200px;
}
.h1{
    font-size: 5rem;
    font-weight: bolder;
    text-transform: uppercase;
}
.correction2{
    height: 280px;
    overflow: hidden;
    
}
.correction2 .correction2-gradient{
    background-color: rgba(0, 0, 0, 0.357);
    transition: 1s; 
    gap: 1.5rem;
}
.correction2:hover img, .correction2:hover .correction2-gradient{
    transform: scale(1.1);
}