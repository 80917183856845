.questionlevel{
    width: 100%;
    background-color: rgb(174, 204, 213);
    padding: 1rem;
}
.number{
    width: 3rem;
    height: 3rem;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bg-yellow{
    background-color: yellow;
}
.bg-green{
    background-color: green;
}
.bg-red{
    background-color: red;
}
.smallcircle{
    width: 1rem;
    height: 1rem;
}