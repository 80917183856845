.plateformebox1{
    gap: 6rem;
}
.plateformebox1 *{
    text-align: start;
}
.plateformebox3{
    width: 40rem;
    margin: 2rem auto 8rem auto;
}
.plateforme .img{
    border-radius: 1rem;
    border: 5px solid blue;
} 
.plateform2{
    width: 100%;
    max-width: 1200px;
    margin: 5rem auto;
}
.plateforme3{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto
}
